import React from 'react';
import { EditableText } from './TextEditor';
import CustomizedButton from '@/components/WidgetMaker/WidgetDnD/WIdgetPropertiesBasic/components/Button';
import { TEXT_ALIGNMENT_POSITIONS } from '@/components/WidgetMaker/utils/constant';
import { getButtonVariantsData } from '../../Carousel/utils';
import { btnActionHandler } from '@/components/DesignSystem/ModernTheme/utils/btnUtils';
import { twMerge } from 'tailwind-merge';
import { BASE_SECTION_BACKGROUND_TYPES, OVERLAP_TYPE } from '../utils';
import { useWidgetDndContextData } from 'src/context/WidgetDndContext';
import {
  BUTTON_SIZE_TYPE,
  BUTTON_TYPE,
} from 'src/components/WidgetMaker/utils/buttonConstant';
import { useGetDeviceType } from 'src/hooks/useGetDeviceType';
import { isEqual } from 'lodash';

export function TextWidgetSection({ data, widgetOptions, currentIndex, widgetId }) {
  const {
    widgetContextState,
    widgetContextHelpers: { handleWidgetPropChange },
  } = useWidgetDndContextData();
  const { widgets, activeWidgetId } = widgetContextState;

  const activeWidget = widgets?.[activeWidgetId];
  const activeWidgetProps = activeWidget?.props;

  const { deviceType } = useGetDeviceType();
  const isMobile = deviceType === 'mobile';
  const sectionConfig = widgetOptions?.textSection;
  const baseConfig = widgetOptions?.baseConfig;
  const isOverlap = baseConfig.overlapType === OVERLAP_TYPE.OVERLAP;
  const textGroupItem = data;
  const isSelected =  activeWidgetProps?.subWidgetIndex === currentIndex && (activeWidget?.name === 'RichTextWithImage') 
  && isEqual(activeWidgetProps?.textGroupSection, widgetOptions?.textGroupSection)

  function getSubtitleSize(): string {
    switch (textGroupItem?.subtitleSize) {
      case BUTTON_SIZE_TYPE.SMALL:
        return '!tw-text-[10px]';
      case BUTTON_SIZE_TYPE.MEDIUM:
        return '!tw-text-[12px]';
      case BUTTON_SIZE_TYPE.LARGE:
        return '!tw-text-[14px]';
      default:
        return '!tw-text-[10px]';
    }
  }

  function onNavigate(e, index: number) {
    if (widgetId) {
      handleWidgetPropChange(
        {
          subWidget: 'TextGroup',
          subWidgetIndex: index,
          textGroupSection: activeWidgetProps?.textGroupSection,
        },
        widgetId
      );
    }
  }

  return (
    <div
      style={{
        textAlign:
          TEXT_ALIGNMENT_POSITIONS?.[
            isMobile ? sectionConfig?.mobileAlignment : sectionConfig?.alignment
          ],
      }}
      className={`tw-relative tw-z-[100] tw-flex tw-w-full tw-flex-col ${
        isMobile
          ? 'tw-justify-center tw-p-[20px]'
          : `tw-justify-${sectionConfig?.position} tw-p-[60px]`
      }`}
    >
      <div
        onClick={(e) => {
          e.preventDefault();
          onNavigate(e, currentIndex);
        }}
        style={{
          border:
          isSelected ? '1px solid #2B74D8' : '',
        }}
        className={twMerge(
          `tw-relative tw-flex tw-flex-col ${isMobile ? 'tw-w-[90%]' : isOverlap ? 'tw-w-[100%]' : 'tw-w-[80%]'} tw-mx-auto`,
          textGroupItem?.subtitlePosition === 'bottom'
            ? 'tw-flex-col-reverse'
            : ' tw-gap-[12px]'
        )}
      >
        {isSelected && (
          <div className="tw-absolute tw-top-[-27.5px] tw-w-[108px] tw-bg-[#2F80ED] tw-px-[12px] tw-py-[4px] tw-text-center">
            <span className="tw-text-[14px] tw-font-normal tw-text-[#fff]">{`Text group ${activeWidgetProps?.subWidgetIndex + 1}`}</span>
          </div>
        )}
        {textGroupItem?.subtitle?.replace('<p></p>', '') ? (
          <EditableText
            content={textGroupItem?.subtitle}
            className={twMerge(
              'tw-font-normal tw-leading-[20px]',
              getSubtitleSize(),
              `${textGroupItem?.subtitlePosition === 'bottom' && textGroupItem?.button[0]?.isShow ? 'tw-mt-[24px]' : ''}`
            )}
          />
        ) : null}
        <div>
          {textGroupItem?.title ? (
            <EditableText
              content={textGroupItem?.title}
              className="tw-font-normal tw-leading-[41px]"
            />
          ) : null}
          {textGroupItem?.description ? (
            <EditableText
              content={textGroupItem?.description}
              className="tw-mt-[12px] tw-text-[16px] tw-font-normal tw-leading-[1.4]"
            />
          ) : null}
          {textGroupItem?.button?.length && (
            <div className="tw-mt-[28px]">
              {textGroupItem?.button?.length ? (
                <div
                  className={`tw-flex tw-w-full`}
                  style={{
                    justifyContent:
                      TEXT_ALIGNMENT_POSITIONS?.[
                        isMobile
                          ? sectionConfig?.mobileAlignment
                          : sectionConfig?.alignment
                      ],
                  }}
                >
                  {textGroupItem?.button?.map((buttonData, btnIndex) => {
                    const buttonVariant = getButtonVariantsData(buttonData);
                    const selectedButtonType = buttonVariant[buttonData.type];
                    return buttonData.isShow ? (
                      <CustomizedButton
                        key={btnIndex}
                        size={BUTTON_SIZE_TYPE.LARGE}
                        btnType={buttonData.type}
                        buttonData={buttonData}
                        selectedButtonData={selectedButtonType}
                        variant={selectedButtonType.variant}
                        onClick={() => btnActionHandler(buttonData?.actions, () => {})}
                        className={twMerge(
                          `!tw-w-auto !tw-rounded-[0px]  ${buttonData.type === BUTTON_TYPE.TEXTUAL ? '!tw-px-[0px]' : '!tw-px-[36px]'} !tw-pb-[12px] !tw-pt-[12px] !tw-text-[14px] !tw-font-[500] !tw-leading-[20px] tw-text-newBlack`
                        )}
                      >
                        {buttonData?.text}
                      </CustomizedButton>
                    ) : null;
                  })}
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export function TextWidgetContainer({
  widgetOptions,
  textContainerRef,
  getImageHeight,
  width,
  widgetId,
}) {
  const { deviceType } = useGetDeviceType();
  const isMobile = deviceType === 'mobile';
  const { imageSection, baseConfig } = widgetOptions;
  const sectionConfig = widgetOptions?.textSection;

  function getMobileOverlap() {
    const imageConfig = widgetOptions?.imageSection;
    if (imageConfig?.mobile?.imageAlignment === 'top') {
      return '-40px';
    } else {
      return '40px';
    }
  }

  function getHeight(position: string) {
    const imageConfig = widgetOptions?.imageSection;
    const numberOfTextWidget = widgetOptions?.textGroupSection?.groupItemsList?.length;
    const heightOfContent = textContainerRef?.current?.offsetHeight;
    const textContainerHeight = heightOfContent > 300 ? heightOfContent : 300;
    const imageHeight =
      numberOfTextWidget > 1
        ? getImageHeight(imageConfig.desktop.height)
        : Number(imageConfig.desktop.height?.replace('px', ''));
    if (textContainerHeight >= imageHeight) {
      return '40px';
    } else {
      const diff = imageHeight - textContainerHeight;
      switch (position) {
        case 'start': {
          return diff > 80 ? `40px` : `${diff / 2}px`;
        }
        case 'center': {
          return `${diff / 2}px`;
        }
        case 'end': {
          return diff > 80 ? `${diff / 2 - 40 + diff / 2}px` : `${diff / 2}px`;
        }
      }
    }
    return '';
  }

  function getTextContainerStyle() {
    const sectionConfig = widgetOptions?.textSection;
    const baseConfig = widgetOptions?.baseConfig;
    return {
      width: isMobile && baseConfig.overlapType === OVERLAP_TYPE.OVERLAP ? '' : width,
      color: sectionConfig.textColor,
      top:
        baseConfig.overlapType === OVERLAP_TYPE.OVERLAP
          ? isMobile
            ? getMobileOverlap()
            : getHeight(sectionConfig.position)
          : '',
      right:
        baseConfig.overlapType === OVERLAP_TYPE.OVERLAP
          ? isMobile
            ? ''
            : imageSection?.desktop?.imageAlignment === 'left'
              ? '40px'
              : '-40px'
          : '',
      border:
        widgetOptions?.baseConfig?.showBorder &&
        widgetOptions?.baseConfig?.overlapType === OVERLAP_TYPE.OVERLAP
          ? '1px solid #e2e4e7'
          : '',
      ...(sectionConfig.bgFillType === 'image'
        ? {
            backgroundImage: `url(${sectionConfig?.bgImage?.url || ''})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }
        : { background: sectionConfig?.backgroundColor }),
      height:
        baseConfig.overlapType === OVERLAP_TYPE.OVERLAP && !isMobile
          ? `${textContainerRef?.current?.offsetHeight}px`
          : '',
    };
  }
  return (
    <div
      style={getTextContainerStyle() as any}
      className={`tw-relative tw-min-h-[300px] 
      ${isMobile && baseConfig.overlapType === OVERLAP_TYPE.OVERLAP ? 'tw-mx-auto tw-w-[90%]' : 'tw-w-full'} 
       ${isMobile ? 'tw-justify-center' : `tw-flex tw-flex-col tw-justify-${sectionConfig?.position}`}`}
    >
      {widgetOptions?.textSection?.bgImage?.url &&
      widgetOptions?.textSection?.bgFillType === BASE_SECTION_BACKGROUND_TYPES.image ? (
        <div
          className={`tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 tw-z-[1] tw-bg-black`}
          style={{
            background: `rgba(0,0,0, ${(widgetOptions?.textSection?.bgImage?.opacity || 1) / 100 || 0.2})`,
          }}
        ></div>
      ) : null}
      <div
        ref={textContainerRef}
        className={`tw-flex tw-w-[100%] tw-flex-col ${isMobile ? 'tw-py-[20px]' : ''}`}
      >
        {widgetOptions?.textGroupSection?.groupItemsList?.map((data, index) => {
          if (data.isVisible === false) {
            return null;
          }
          return (
            <TextWidgetSection
              key={index}
              currentIndex={index}
              data={data}
              widgetOptions={widgetOptions}
              widgetId={widgetId}
            />
          );
        })}
      </div>
    </div>
  );
}
